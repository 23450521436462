<template>
  <span
    class="image-view inline-block align-bottom bg-surface-min-contrast rounded-sm"
    :class="{ 'cursor-zoom-in': detailView }"
    @mouseover="hovering = true"
    @mouseleave="hovering = false"
    @click="toggleHover"
  >
    <img
      v-lazy="hovering && hoverUrl ? hoverUrl : mediumUrl"
      class="image inline rounded-sm"
      :style="imageStyle"
      @click="!hasHoverImage && (detailOpen = true)"
    />
    <transition v-if="detailView" name="fade">
      <div v-if="detailOpen" class="detail-view z-50" @click="detailOpen = false">
        <img :src="url" />
      </div>
    </transition>
  </span>
</template>

<script lang="ts">
import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";
import { filePath } from "@/utils/paths";
import ContentBlob from "@/model/content-blob";

@Component({})
export default class ImageView extends Vue {
  @Prop()
  blob: ContentBlob;

  @Prop()
  width: number;

  @Prop({ default: true })
  detailView: boolean;

  @Prop({ default: null })
  hoverBlob: ContentBlob;

  detailOpen = false;
  hovering = false;
  hoverToggled = false;

  get imageStyle() {
    const style: any = {};

    if (this.blob.metadata?.width) {
      style.width = this.blob.metadata.width + "px";
      if (this.blob.metadata.height) {
        style.aspectRatio = this.blob.metadata.width / this.blob.metadata.height;
      }
    }

    if (this.width) {
      style.width = this.width + "px";
    }

    return style;
  }

  get url() {
    return filePath(this.blob);
  }

  get mediumUrl() {
    return filePath(this.blob, "medium");
  }

  get hoverUrl() {
    return this.hoverBlob ? filePath(this.hoverBlob) : null;
  }

  get hasHoverImage() {
    return !!this.hoverBlob;
  }

  toggleHover() {
    this.hoverToggled = !this.hoverToggled;
    this.hovering = this.hoverToggled;
  }
}
</script>

<style lang="scss" scoped>
.image-view {
  // Max width = content width - 1px (so that it will not automatically break to new line in editor)
  max-width: calc(100% - 1px);

  > img {
    opacity: 0;
    transition: opacity 0.3s ease;

    &[lazy="loaded"] {
      opacity: 1;
      background-color: var(--ui-color-surface-min-contrast);
    }
  }

  .detail-view {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;

    padding: var(--spacing);
    cursor: zoom-out;

    img {
      max-width: 100%;
      max-height: 100%;
      background-color: var(--ui-color-surface-no-contrast);
    }

    transition: opacity 0.5s ease;
  }
}
</style>
